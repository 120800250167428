import defaultTheme from 'tailwindcss/defaultTheme';
import { colors } from './colors';
import type { CustomThemeConfig } from 'tailwindcss/types/config';

export const theme: Partial<CustomThemeConfig> = {
	...defaultTheme,
	colors: colors,
	borderRadius: {
		none: '0',
		sm: '0.125rem', // 2px
		md: '0.3125rem', // 5px
		DEFAULT: '0.625rem', // 10px
		lg: '0.625rem', // 10px
		full: '9999px', // circle
	},
	extend: {
		animation: {
			stroke: 'stroke 1.5s ease-in-out infinite',
		},
		backgroundImage: {
			hero: 'linear-gradient(242deg, rgba(0, 0, 0, 0.3) 36.43%, rgba(0, 0, 0, 0.75) 72.04%)',
		},
		keyframes: {
			stroke: {
				'0%': { 'stroke-dasharray': '1, 150', 'stroke-dashoffset': '0' },
				'50%': { 'stroke-dasharray': '90, 150', 'stroke-dashoffset': '-35' },
				'100%': { 'stroke-dasharray': '90, 150', 'stroke-dashoffset': '-125' },
			},
		},
		lineHeight: {
			'0': '0',
		},
	},
};
